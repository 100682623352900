<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
     <!-- <div class="m-2"> -->
     <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->
              <b-button
                variant="primary"
                size="sm"
                v-b-modal.modal-tambah
              >
                <span class="text-nowrap">Tambah</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
     </div>

    <b-table
      small
      :fields="fields"
      :items="hak_akses"
      responsive="sm"
    >
      <!-- A virtual column -->
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(nama)="data">
        {{ data.item.nama }}
      </template>

      <!-- A custom formatted column -->
      <template #cell(id)="data">
          <b-button v-b-modal.modal-prevent-closing size="sm" variant="success" @click="getdata(data.item.id)">Edit</b-button>
          <b-button class="ml-1" size="sm" variant="danger" @click="deletedata(data.item.id)">Delete</b-button>
      </template>

    </b-table>

     <b-modal
      id="modal-tambah"
      ref="modal"
      title="Tambah Data"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk2"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit2">
        <b-form-group
          label="Nama"
          label-for="name-input"
          invalid-feedback="Nama is required"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="nama"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>

     <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Ubah Data"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk2"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit2">
        <b-form-group
          label="Nama"
          label-for="name-input"
          invalid-feedback="Nama is required"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="datahakakses.nama"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
    </b-card>
  </div>
</template>

<script>
import { BTable, BProgress, BBadge, BCard, BButton, BFormGroup, BFormInput } from 'bootstrap-vue'
import axios from 'axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BProgress,
    BBadge,
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    ToastificationContent
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        'no',
        // A column that needs custom formatting
        { key: 'nama', label: 'Nama' },
        // 'Category',
        // A regular column
        { key: 'id', label: 'Aksi' },
        // // A regular column
        // { key: 'order_status', label: 'Order Status' },
        // // A virtual column made up from two fields
        // { key: 'price', label: 'Price' },
      ],
      url:localStorage.getItem('baseapi'),
      pengguna:JSON.parse(localStorage.getItem('userData')),
      hak_akses:[],
      datahakakses:[],
      nameState: null,
      nama:'',
    }
  },
  methods: {
      loaddata(){
        axios.get(this.url+'hak_akses',{
            headers: {
              'xth': this.pengguna.token
            }
          })
        .then(response => {
          var data = response.data.data
          this.hak_akses = data
          console.log(this.hak_akses)
        }).catch(error =>{
            return error
        })
      },
      getdata(id){
        axios.get(this.url+'hak_akses/'+id,{
            headers: {
              'xth': this.pengguna.token
            }
          })
        .then(response => {
          var data = response.data.data
          this.datahakakses = data
          console.log(this.hak_akses)
        }).catch(error =>{
            return error
        })
      },
      checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.nameState = valid
        return valid
      },
      resetModal() {
        this.datahakakses = []
        this.nameState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleOk2(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit2()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        const nama = this.nama
        axios.post(this.url+'hak_akses', {
            nama: nama,
        },{
            headers: {
                "Content-type": "application/json",
                "xth": this.pengguna.token,
                }
            }
            )
            .then((response) => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Sukses`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `Data Berhasil Ditambah`,
                    },
            })
            this.loaddata
            })
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-tambah')
        })
      },
      handleSubmit2() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        axios.put(this.url+'hak_akses/'+this.datahakakses.id, {
            nama: this.datahakakses.nama,
        },{
            headers: {
                "Content-type": "application/json",
                "xth": this.pengguna.token,
                }
            }
            )
            .then((response) => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: `Sukses`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: `Data Berhasil Diubah`,
                    },
            })
            this.loaddata
            })
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      },
      deletedata(id) {
        this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
      }).then(result => {
        if (result.value) {
        axios.delete(this.url+`hak_akses/`+id,
            {
                headers: {
                "Content-type": "application/json",
                "xth": this.pengguna.token,
                }
            }
            )
            .then((res) => {
                this.$swal({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Your file has been deleted.',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                })
                this.loaddata
                // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
                return res;
            })
            .catch((err) => {
                console.log(err)
                return err;
            });
        }
      })
    },
  },
  mounted() {
      this.loaddata()
  },    
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>